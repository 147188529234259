import styled from "styled-components";

export const Button = styled.a`
  display: inline-block;
  cursor: pointer;
  font-family: ${props => props.theme.font.extrabold};
  ${props => props.theme.fontSize.xsmall};
  line-height: 16px;
  @media (max-width: ${props => props.theme.screen.xs}) {
    ${props => props.theme.fontSize.xxsmall};
  }
  letter-spacing: 1px;
  // height: 1em;
  margin-left: 0px;
  margin-top: -0.5em;
  text-transform: uppercase;
  white-space: nowrap;
  background: ${props => props.theme.color.button};
  color: #0c017a !important;
  border-radius: 25px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 2em;
  padding-right: 2em;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`;
export const StyledLink = styled.a`
  -webkit-text-fill-color: ${props => props.theme.color.text.onBackground};
  color: ${props => props.theme.color.text.onBackground};
`;
