import BaseButton, { ButtonProps } from "@material-ui/core/Button";
import React from "react";

type Props = ButtonProps & { fill?: "blue" | "white" | "grey" | "none"; height?: "small" | "medium" };

const getBackgroundColor = fill => {
  switch (fill) {
    case "blue":
      return "#0055D7";
    case "grey":
      return "#e6e6e6";
    case "none":
      return "transparent";
    case "white":
    default:
      return "#ffffff";
  }
};

const getColor = fill => {
  switch (fill) {
    case "blue":
      return "#ffffff";
    case "none":
      return "#ffffff";
    case "white":
      return "#0C017A";
    case "grey":
    default:
      return "#0055D7";
  }
};

const Button: React.FC<Props> = ({ fill, height, style = {}, fullWidth = true, ...other }) => {
  return (
    <BaseButton
      component="span"
      fullWidth={fullWidth}
      {...other}
      style={{
        backgroundColor: getBackgroundColor(fill),
        color: getColor(fill),
        border: fill === undefined ? "1px solid #0055D7" : "none",
        height: height === "small" ? "30px" : "48px",
        borderRadius: height === "small" ? "15px" : "24px",
        padding: !fullWidth ? "0px 15px" : undefined,
        whiteSpace: "nowrap",
        ...style,
      }}
    />
  );
};

export default Button;
