import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";

const initTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0055D7",
    },
    secondary: {
      main: "#0C017A",
    },
  },
});

const theme = responsiveFontSizes(initTheme);

export default theme;
