import styled from "styled-components";
import Logo from "./akomaps.inline.svg";

const AkoMapsLogo = styled(Logo)`
  height: 40px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100px;
  }
`;
export default AkoMapsLogo;
